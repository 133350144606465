import packageJson from '../../../../package.json';

export const environment: {
    appName: string;
    test: boolean;
    debug: boolean;
    attributes: boolean,
    apiUrl: string;
    production: boolean;
    listingPagination: boolean;
    envName: string;
    version: string;
    userManagementApiUrl: string;
    pictureManagerApiUrl: string;
    awsCognitoIdentityPoolId: string;
    awsUserPoolsId: string;
    awsUserPoolsWebClientId: string;
    pictureManagerCloudfrontDistribution: string;
} = {
    appName: "Lynkd Pattern Analytics",
    envName: 'PROD',
    debug: false,
    production: true,
    test: false,
    attributes: true,
    listingPagination: false,
    version: packageJson.version,
    apiUrl: 'https://t40gsew335.execute-api.af-south-1.amazonaws.com/prod',
    userManagementApiUrl: 'https://c4h3l71sd4.execute-api.af-south-1.amazonaws.com/prod',
    pictureManagerApiUrl: '',
    awsCognitoIdentityPoolId: 'eu-west-1:a45f248b-654d-4a33-bb68-7170e5a079ab',
    awsUserPoolsId: 'eu-west-1_Xl27QjWlw',
    awsUserPoolsWebClientId: '3qp141pvg89o7esf30kcs2ng23',
    pictureManagerCloudfrontDistribution: ''
};
