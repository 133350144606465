import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { LoadingSpinnerService } from '../../../core/data/loading-spinner.service';
import { IBoard } from '../../../core/models/board.model';
import { ICurrentBoard } from '../../../core/models/current-board.model';
import { defaultDisplayAttributes } from '../../../core/models/default-display-attributes.const';
import { IFilterData } from '../../../core/models/filter-data.model';
import { IPaginationLabels } from '../../../core/models/pagination-labels.model';
import { IProductAttributeParameters } from '../../../core/models/product-attribute-parameters.model';
import { IProductAttributeRow } from '../../../core/models/product-attribute-row.model';
import { IProductDisplayAttribute } from '../../../core/models/product-display-attribute.model';
import { IState } from '../../../core/store/store.model';
import { selectBoardData, selectFilterData } from '../../../core/store/store.selectors';
import { AttributeService } from '../../../shared/services/attribute.service';
import { DefaultFiltersService } from '../../../shared/services/default-filters.service';

@Component({
    selector: 'lynkd-pattern-attribute-listing',
    templateUrl: './attribute-listing.component.html',
    styleUrls: ['./attribute-listing.component.scss']
})
export class AttributeListingComponent implements OnInit, OnDestroy {
    public attributeParameters: IProductAttributeParameters;
    public currentBoard: ICurrentBoard = {
        user_id: '',
        board_id: 0,
        board_name: '',
        time_ranges: undefined as string,
        time_periods: [],
        time_years: [],
        time_quarters: [],
        time_months: [],
        location_companies: [],
        location_divisions: [],
        location_areas: [],
        location_regions: [],
        location_stores: [],
        fields: '',
        departments: [],
        sub_departments: [],
        categories: [],
        product_types: [],
        meta_datas: {},
        metric_ranges: [],
        display_metrics: [],
        type: '',
        exclusions: [],
        display_attributes: defaultDisplayAttributes,
        display_attribute_metrics: []
    };
    public attributes: Array<IProductDisplayAttribute>;
    public itemsPerPage: number = 3;
    public attributeRows: Array<{ attribute: IProductDisplayAttribute; rows: Array<IProductAttributeRow> }> = [];
    public currentPage: number;
    public labels: IPaginationLabels = {
        previousLabel: '',
        nextLabel: ''
    };
    private _isActive: boolean = true;

    public constructor(private readonly _store: Store<IState>,
                       private readonly _defaultFilters: DefaultFiltersService,
                       private readonly _attributeService: AttributeService,
                       private readonly _spinnerService: LoadingSpinnerService) {
    }

    public async ngOnInit(): Promise<void> {
        this.attributes = await this._attributeService.getProductDisplayAttributes();

        this._store.select(selectBoardData)
            .pipe(takeWhile(() => this._isActive))
            .subscribe((result: IBoard) => {
                this.currentBoard.board_id = result.board_id;
                this.currentBoard.board_name = result.board_name;
                this.currentBoard.type = result.user_id;
            });

        this._store.select(selectFilterData)
            .pipe(takeWhile(() => this._isActive))
            .subscribe(async (result: IFilterData) => {
                this._spinnerService.spin$.next(true);
                const oldAttributeParameters: IProductAttributeParameters = this.attributeParameters;
                this.attributeParameters = await this.setAttributeParameters(result);
                this.currentPage = 1;
                await this.hydrateAttributes();

                if (this.haveParametersChanged(oldAttributeParameters)) {
                    await this._attributeService.getDefaultProductDisplayAttributeMetrics();
                    await this.loadAttributeRows();
                }

                this._spinnerService.spin$.next(false);
            });
    }

    public ngOnDestroy(): void {
        this._isActive = false;
    }

    public async changePage($event: number): Promise<void> {
        this.currentPage = $event;
        this._spinnerService.spin$.next(true);
        try {
            await this.loadAttributeRows();
        } catch (e) {
            this._spinnerService.spin$.next(false);
        }
        this._spinnerService.spin$.next(false);
    }

    private async loadAttributeRows(): Promise<void> {
        this.attributeRows = [];
        const skip: number = (this.currentPage - 1)
            * this.itemsPerPage;
        const take: number = skip + this.itemsPerPage;
        const attributesOnScreen: Array<IProductDisplayAttribute> = this.attributes.slice(skip, take);
        for (const attribute of attributesOnScreen) {
            const rows: Array<IProductAttributeRow> = await this._attributeService
                .getProductAttributes({
                    ...this.attributeParameters,
                    attribute_column: attribute.column_name
                });
            this.attributeRows.push({
                attribute,
                rows
            });

        }
    }

    private async hydrateAttributes(): Promise<void> {
        if (this.attributeParameters.display_attributes && this.attributeParameters.display_attributes.length > 0) {
            const columnNames: Array<string> = this.attributeParameters.display_attributes
                .map((t: IProductDisplayAttribute) => t.column_name);
            const fullAttributes: Array<IProductDisplayAttribute> = await this._attributeService
                .getProductDisplayAttributes();
            this.attributes = fullAttributes.filter((x: IProductDisplayAttribute) => columnNames.includes(x.column_name));

        } else {
            this.attributes = await this._attributeService.getProductDisplayAttributes();
        }
    }

    private async setAttributeParameters(result: IFilterData): Promise<IProductAttributeParameters> {
        const filterData: IFilterData = JSON.parse(JSON.stringify(result));
        filterData.time_period = await this.calculateTimePeriodFilter(filterData);
        filterData.time_period =
            filterData.time_period.length ||
            filterData.time_year.length ||
            filterData.time_quarter.length ||
            filterData.time_month.length
                ? filterData.time_period
                : '';

        const attributeParameters: IProductAttributeParameters = {
            attribute_column: '',
            pageSize: 8000,
            ...filterData
        } as IProductAttributeParameters;
        attributeParameters.display_attributes =
            !attributeParameters.display_attributes || attributeParameters.display_attributes.length === 0
                ? defaultDisplayAttributes
                : attributeParameters.display_attributes;
        return attributeParameters;
    }

    private haveParametersChanged(oldAttributeParameters: IProductAttributeParameters): boolean {
        return !oldAttributeParameters ||
            !(
                oldAttributeParameters.attribute_column === this.attributeParameters.attribute_column &&
                oldAttributeParameters.ids === this.attributeParameters.ids &&
                oldAttributeParameters.department === this.attributeParameters.department &&
                oldAttributeParameters.sub_department === this.attributeParameters.sub_department &&
                oldAttributeParameters.category === this.attributeParameters.category &&
                oldAttributeParameters.product_type === this.attributeParameters.product_type &&
                oldAttributeParameters.metric_range === this.attributeParameters.metric_range &&
                oldAttributeParameters.fieldsSet === this.attributeParameters.fieldsSet &&
                oldAttributeParameters.sortOrder === this.attributeParameters.sortOrder &&
                oldAttributeParameters.pageSize === this.attributeParameters.pageSize &&
                oldAttributeParameters.meta_data === this.attributeParameters.meta_data &&
                // oldAttributeParameters.location_store === this.attributeParameters.location_store &&
                // oldAttributeParameters.location_region === this.attributeParameters.location_region &&
                // oldAttributeParameters.location_area === this.attributeParameters.location_area &&
                // oldAttributeParameters.location_division === this.attributeParameters.location_division &&
                // oldAttributeParameters.location_company === this.attributeParameters.location_company &&
                oldAttributeParameters.time_month === this.attributeParameters.time_month &&
                oldAttributeParameters.time_quarter === this.attributeParameters.time_quarter &&
                oldAttributeParameters.time_year === this.attributeParameters.time_year &&
                oldAttributeParameters.time_range === this.attributeParameters.time_range &&
                oldAttributeParameters.time_period === this.attributeParameters.time_period &&
                oldAttributeParameters.display_attributes === this.attributeParameters.display_attributes
            );
    }

    private hasTimeFilter(filterData: IFilterData): boolean {
        return (
            (filterData.time_month && filterData.time_month.length !== 0) ||
            (filterData.time_quarter && filterData.time_quarter.length !== 0) ||
            (filterData.time_year && filterData.time_year.length !== 0) ||
            (filterData.time_range && filterData.time_range.length !== 0) ||
            (filterData.time_period && filterData.time_period.length !== 0)
        );
    }

    private async calculateTimePeriodFilter(filterData: IFilterData): Promise<string> {
        if (this.hasTimeFilter(filterData)) {
            return filterData.time_period;
        } else {
            const defaults: IFilterData = await this._defaultFilters.getAnalyticsDefaults();
            return defaults?.time_period;
        }
    }
}
