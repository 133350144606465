<lynkd-pattern-application-navigation-bar
    [gallery]="gallery"
    [filters]="filters"
    [filterData]="filterData"
    [toolbarOpenState]="toolbarOpenState"
    [inputs]="inputs"
    [attributeDisplayMetrics]="attributeDisplayMetrics"
    [isAuthenticated$]="isAuthenticated$"
    [sortToggleAscending]="sortToggleAscending"
    (sortToggled)="sortToggle()"
    (searchChanged)="searchFilter()"
    (clearClicked)="clearSortAndDisplay()"
    (openDisplayAttributeClicked)="
        selectFilter(
            filters.display_attributes,
            filterData.display_attributes,
            DialogFilterTypes.DisplayAttributes,
            null,
            gallery,
            DialogDisplayMode.Singular,
            null
        )
    "
    (openSortClicked)="
        selectFilter(
            filters?.sort_order,
            inputs?.sort_order,
            DialogFilterTypes.SortOrder,
            null,
            null,
            DialogDisplayMode.Singular,
            'gallery'
        )
    "
    (openDisplayMetricsClicked)="
        selectFilter(
            filters.display_metrics,
            filterData.display_metrics,
            DialogFilterTypes.DisplayMetrics,
            null,
            gallery,
            DialogDisplayMode.Singular,
            null
        )
    "
    (openAttributeDisplayMetricsClicked)="
        selectFilter(
            filters.display_attribute_metrics,
            attributeDisplayMetrics,
            DialogFilterTypes.DisplayAttributeMetrics,
            null,
            gallery,
            DialogDisplayMode.Singular,
            null
        )
    "
>
</lynkd-pattern-application-navigation-bar>

<!-- Status Bar------------------------------------------------------------------------ -->

<div
    *ngIf="isAuthenticated$ | async"
    class="row align-items-left align-items-md-center justify-content-between dashboard-toolbar"
    [class.opened]="toolbarOpenState === true"
>
    <div class="col-auto">
        <lynkd-pattern-application-breadcrumbs
            class="h-100 d-flex align-items-center justify-content-center"
            [filterData]="listingFilterData"
            [gallery]="gallery"
            [filtersApplied]="filtersApplied"
            (filterRemoved)="onFilterRemoved($event.filter, $event.filterItem, $event.subFilter)"
            (mainFilterRemoved)="onMainFilterRemoved($event.filter, $event.filterItem)"
            [checkActiveMetricRanges]="checkActiveMetricRanges()"
        >
        </lynkd-pattern-application-breadcrumbs>
    </div>

    <!-- ------------------------------------------------------------------------------ -->

    <div class="col align-right button-group">
        <button mat-button (click)="resetFilters()">
            <img src="assets/icon-reset.svg" alt="close button" class="mr-1px" />
            Reset all filters
        </button>
        <button mat-button (click)="clearAllFilters()">
            <img src="assets/icon-clear.svg" alt="close button" class="mr-1px" />
            Clear all filters
        </button>
        <button mat-button (click)="saveBoard()">
            <img src="assets/icon-save.svg" alt="close button" class="mr-1px" />
            Save board
        </button>
        <button *ngIf="isDownloadingExportData" mat-button>
            <!--TODO Investigate missing icon or find replacement -->
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="mr-1">
                <g fill="none" fill-rule="evenodd">
                    <path fill="#666" fill-rule="nonzero" d="" transform="translate(-1330 -71) translate(1330 71)" />
                    <path d="M0 0L18 0 18 18 0 18z" transform="translate(-1330 -71) translate(1330 71)" />
                </g>
            </svg> -->
            Downloading...
        </button>
        <button *ngIf="gallery === ListingDisplayMode.Gallery" mat-button (click)="showExportDialog()">
            <img src="assets/icon-file.svg" width="18px" height="auto" alt="file download" class="mr-1" />
            Export Gallery
        </button>
        <button
            *ngIf="
                (gallery === ListingDisplayMode.Table || gallery === ListingDisplayMode.Attributes) && !isDownloadingExportData
            "
            mat-button
            (click)="exportExcel()"
        >
            <img src="assets/icon-file.svg" width="18px" height="auto" alt="file download" class="mr-1" />
            Export to Excel
        </button>
    </div>
</div>

<div *ngIf="products && gallery !== ListingDisplayMode.Attributes" class="row board-details">
    <div class="col-auto">
        <h4 *ngIf="currentBoard.board_name.length > 0">
            {{ currentBoard.board_name }}
        </h4>
        <ng-container *ngIf="!environment.listingPagination">
            <p *ngIf="products.length" class="m-0">{{ products.length }} Results found</p>
        </ng-container>
        <ng-container *ngIf="environment.listingPagination">
            <p *ngIf="products?.length > 0 && products[0].results_count" class="m-0">
                {{ products[0].results_count }} Results found
            </p>
        </ng-container>
    </div>

    <div *ngIf="gallery === ListingDisplayMode.Gallery" class="col align-right">
        <pagination-controls
            *ngIf="products?.length > 0"
            (pageChange)="changePage($event)"
            [previousLabel]="labels.previousLabel"
            [nextLabel]="labels.nextLabel"
        ></pagination-controls>
    </div>
</div>

<!-- Gallery---------------------------------------------------------------------- -->

<ng-container *ngIf="(products && products.length) || productsLoading; else noItems">
    <div *ngIf="gallery === ListingDisplayMode.Gallery" [transition-group]="'flip-list'">
        <lynkd-pattern-gallery-listing
            [products]="products"
            [filterData]="filterData"
            [currentBoard]="currentBoard"
            [inputs]="inputs"
            [sortDirection]="sort_direction"
            [gallery]="gallery"
            [currentPage]="currentPage"
            [itemsPerPage]="itemsPerPage"
            [itemHeight]="itemHeight"
            [metricAlignment]="dataService.metricAlignment | async"
            [showDisplayMetrics]="showDisplayMetrics"
            (selectProduct)="viewProduct($event)"
        >
        </lynkd-pattern-gallery-listing>
    </div>

    <!-- Table---------------------------------------------------------- -->

    <ng-container *ngIf="gallery === ListingDisplayMode.Table && products.length !== 0">
        <lynkd-pattern-table-listing
            [products]='products'
            [gridColumns]="gridColumns"
            [displayMetrics]="filterData?.display_metrics"
            (drop)="drop($event)"
            (selectProduct)="viewProduct($event)"
            (excludeProduct)="exclude($event)"
        ></lynkd-pattern-table-listing>
    </ng-container>
</ng-container>

<div class="row no-gutters product-attributes" *ngIf="gallery === ListingDisplayMode.Attributes">
    <lynkd-pattern-attribute-listing
        class="col-12 col-md-12 col-lg-12"
    ></lynkd-pattern-attribute-listing>
</div>

<!--<div class="row d-flex footer-row" *ngIf="lastUpdated">-->
<!--    <div>-->
<!--        <p class="my-auto build-version float-end">Build Version: 4.1.0.2</p>-->
<!--    </div>-->
<!--    <div class="ml-auto">-->
<!--        <p class="my-auto last-updated">Last updated: {{ lastUpdated }}</p>-->
<!--    </div>-->
<!--</div>-->

<ng-template #noItems>
    <div class="row product-listing" *ngIf="gallery !== ListingDisplayMode.Attributes">
        <p>No products found</p>
    </div>
</ng-template>

<!-- <ng-template #loading>
  <div class="mt-4">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
-->
