import { Component, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { ICurrentBoard } from '../../../core/models/current-board.model';
import { IDashboardFilters } from '../../../core/models/dashboard-filters.model';
import { IDashboardInputs } from '../../../core/models/dashboard-inputs.model';
import { IFilterData } from '../../../core/models/filter-data.model';
import { IMetricRange } from '../../../core/models/metric-range.model';
import { IProductMetaItem } from '../../../core/models/product-meta-item.model';
import { ITimePeriod } from '../../../core/models/time-period.model';
import { DialogFilterSelectComponent } from '../../../features/dialogs/dialog-filter-select.component';
import { ISideMenuItemModel } from '../../models/side-menu-item.model';

@Component({
    selector: 'lynkd-pattern-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
    @ViewChildren(MatAccordion)
    public accordions: Array<MatAccordion>;
    @Input()
    public visible: boolean;

    @Input()
    public metaDataLoading: boolean;

    @Input()
    public boards$: Observable<Array<ICurrentBoard>>;

    @Input()
    public filterData: IFilterData;

    @Input()
    public filters: IDashboardFilters;

    @Input()
    public inputs: IDashboardInputs;
    @Input()
    public metaData$: Observable<Array<IProductMetaItem>>;
    @Input()
    public savedBoards$: Observable<Array<ICurrentBoard>>;
    @Input()
    public sideMenuConfig$: Observable<Array<ISideMenuItemModel>>;

    @Output()
    public readonly panelOpened: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public readonly boardSelected: EventEmitter<{ saveBoard: boolean; boardId: number }> = new EventEmitter<{
        saveBoard: boolean;
        boardId: number;
    }>();
    @Output()
    public readonly filterSelected: EventEmitter<{
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
        selectedItem: string | Array<string> | Array<IMetricRange>;
        input: string;
        subInput: string;
    }> = new EventEmitter<{
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
        selectedItem: string | Array<string> | Array<IMetricRange>;
        input: string;
        subInput: string;
    }>();

    @Output()
    public readonly metaPanelOpened: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public readonly deleteMetaFieldClicked: EventEmitter<number> = new EventEmitter<number>();
    @Output()
    public readonly addMetaFieldClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public readonly editSavedBoardClicked: EventEmitter<ICurrentBoard> = new EventEmitter<ICurrentBoard>();
    @Output()
    public readonly deleteSavedBoardClicked: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public readonly sideMenuToggled: EventEmitter<Array<MatAccordion>> = new EventEmitter<Array<MatAccordion>>();
    @Output()
    public readonly filterClosed: EventEmitter<{
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
        selectedItem: string | Array<string> | Array<IMetricRange>;
        input: string;
        subInput: string;
    }> = new EventEmitter<{
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
        selectedItem: string | Array<string> | Array<IMetricRange>;
        input: string;
        subInput: string;
    }>();

    public sideNavOpenState: boolean = false;

    public constructor(private readonly _dialog: MatDialog) {}

    public openPanel(): void {
        this.panelOpened.emit();
    }

    public selectBoard(value: { saveBoard: boolean; boardId: number }): void {
        this.boardSelected.emit(value);
    }

    public selectFilter(
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>,
        selectedItem: string | Array<string> | Array<IMetricRange>,
        input: string,
        subInput: string
    ): void {
        const dialogRef: MatDialogRef<DialogFilterSelectComponent, {
            items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
            selectedItem: string | Array<string> | Array<IMetricRange>;
            input: string;
            subInput: string;
        }> = this._dialog.open(DialogFilterSelectComponent, {
            panelClass: input,
            data: {
                filter: input,
                subFilter: subInput ? subInput : null,
                items: items.slice(),
                selectedItem: selectedItem ? selectedItem.slice() : selectedItem
            }
        });

        dialogRef
            .afterClosed()
            .subscribe(
                (result: {
                    items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
                    selectedItem: string | Array<string> | Array<IMetricRange>;
                    input: string;
                    subInput: string;
                }) => {
                    this.filterClosed.emit(result);
                }
            );
    }

    public openMetaPanel(): void {
        this.metaPanelOpened.emit();
    }

    public deleteMetaDataField($event: Event, index: number): void {
        $event.stopImmediatePropagation();
        $event.preventDefault();

        if (!($event.currentTarget as HTMLElement).classList.contains('metadata-delete')) {
            return;
        }
        this.deleteMetaFieldClicked.emit(index);
    }

    public addMetaDataField(): void {
        this.addMetaFieldClicked.emit();
    }

    public editSavedBoard(board: ICurrentBoard): void {
        this.editSavedBoardClicked.emit(board);
    }

    public deleteSavedBoard(boardId: string): void {
        this.deleteSavedBoardClicked.emit(boardId);
    }

    public toggleSideNav(): void {
        this.sideNavOpenState = !this.sideNavOpenState;
        this.sideMenuToggled.emit(this.accordions);
    }

    public closeFilter(value: {
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
        selectedItem: string | Array<string> | Array<IMetricRange>;
        input: string;
        subInput: string;
    }): void {
        this.filterClosed.emit(value);
    }
}
