import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { FormatDisplayNamePipe } from '../core/data/format-display-name.pipe';
import { FormatStringReplacePipe } from './pipes/string-replace-pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';

import { MatTooltipModule } from '@angular/material/tooltip';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { NouisliderModule } from 'ng2-nouislider';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { OverlayModule } from '@angular/cdk/overlay';

import { NgxPaginationModule } from 'ngx-pagination';

import { GalleryModule } from '@ngx-gallery/core';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import {
    faChevronRight,
    faChevronDown,
    faTrash,
    faPlus,
    faTimes,
    faEdit,
    faFileAlt,
    faDotCircle,
    faComment,
    faSpinner,
    faRedo,
    faUndo,
    faBars,
    faUserCircle,
    faPowerOff,
    faCog,
    faPlayCircle,
    faRocket,
    faCaretUp,
    faCaretDown,
    faExclamationTriangle,
    faFilter,
    faTasks,
    faCheck,
    faSquare,
    faLanguage,
    faPaintBrush,
    faLightbulb,
    faWindowMaximize,
    faStream,
    faBook,
    faSave
} from '@fortawesome/free-solid-svg-icons';
import { DescriptionShorterPipe } from './pipes/description-shorter.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SideMenuExpansionPanelComponent } from './components/side-menu-expansion-panel/side-menu-expansion-panel.component';
import { SideMenuSelectComponent } from './components/side-menu-select/side-menu-select.component';
import { SideMenuPatternsBoardsComponent } from './components/side-menu-patterns-boards/side-menu-patterns-boards.component';
import { SideMenuMyBoardsComponent } from './components/side-menu-my-boards/side-menu-my-boards.component';
import { ApplicationMenuComponent } from './components/application-menu/application-menu.component';
import { ApplicationNavigationBarComponent } from './components/application-navigation-bar/application-navigation-bar.component';
import { ApplicationBreadcrumbsComponent } from './components/application-breadcrumbs/application-breadcrumbs.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { AllocationsViewsComponent } from './components/allocations-views/allocations-views.component';
import { AnalyticsViewsComponent } from './components/analytics-views/analytics-views.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { ApplicationBreadcrumbComponent } from './components/application-breadcrumb/application-breadcrumb.component';
import { MatRadioModule } from '@angular/material/radio';
import { BoardSearchModalComponent } from './components/board-search-modal/board-search-modal.component';
import { UserManagerViewsComponent } from './components/user-manager-views/user-manager-views.component';
import { PictureManagerViewsComponent } from './components/picture-manager-views/picture-manager-views.component';
import { MapsViewsComponent } from './components/maps-views/maps-views.component';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';

// eslint-disable-next-line @typescript-eslint/typedef
export const matModules = [
    MatAutocompleteModule,
    MatExpansionModule,
    MatTooltipModule,
    MatButtonModule,
    MatToolbarModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatTableModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatDialogModule,
    MatIconModule,
    MatRadioModule,
    MatChipsModule,
    MatSlideToggleModule,
    CdkTableModule
];

// eslint-disable-next-line @typescript-eslint/typedef
export const nzModules = [
    NzTableModule,
    NzDividerModule,
    NzDropDownModule,
    NzIconModule,
    NzButtonModule,
    NzInputModule,
    NzCheckboxModule,
    NzSelectModule
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        OverlayModule,
        GalleryModule,
        NgxPaginationModule,
        NgxDaterangepickerMd,
        FontAwesomeModule,
        NgOptimizedImage,
        ...matModules,
        ...nzModules
    ],
    declarations: [
        FormatDisplayNamePipe,
        FormatStringReplacePipe,
        ShortNumberPipe,
        DecimalPipe,
        PercentagePipe,
        DescriptionShorterPipe,
        EllipsisPipe,
        DescriptionShorterPipe,
        SideMenuComponent,
        SideMenuExpansionPanelComponent,
        SideMenuSelectComponent,
        SideMenuPatternsBoardsComponent,
        SideMenuMyBoardsComponent,
        ApplicationMenuComponent,
        ApplicationNavigationBarComponent,
        ApplicationBreadcrumbsComponent,
        NavigationBarComponent,
        AllocationsViewsComponent,
        AnalyticsViewsComponent,
        ApplicationBreadcrumbComponent,
        BoardSearchModalComponent,
        UserManagerViewsComponent,
        PictureManagerViewsComponent,
        MapsViewsComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormatDisplayNamePipe,
        FormatStringReplacePipe,
        ShortNumberPipe,
        DecimalPipe,
        EllipsisPipe,
        PortalModule,
        GalleryModule,
        DragDropModule,
        NouisliderModule,
        NgxDaterangepickerMd,
        NgxPaginationModule,
        FontAwesomeModule,
        PercentagePipe,
        DescriptionShorterPipe,
        SideMenuComponent,
        ApplicationMenuComponent,
        ApplicationNavigationBarComponent,
        ApplicationBreadcrumbsComponent,
        NavigationBarComponent,
        ...matModules,
        ...nzModules
    ]
})
export class SharedModule {
    public constructor(library: FaIconLibrary) {
        library.addIcons(
            faChevronRight,
            faChevronLeft,
            faChevronDown,
            faChevronUp,
            faTrash,
            faPlus,
            faTimes,
            faEdit,
            faRedo,
            faUndo,
            faFileAlt,
            faDotCircle,
            faBars,
            faUserCircle,
            faPowerOff,
            faCog,
            faRocket,
            faPlayCircle,
            faPlus,
            faEdit,
            faTrash,
            faTimes,
            faCaretUp,
            faCaretDown,
            faExclamationTriangle,
            faFilter,
            faTasks,
            faCheck,
            faSquare,
            faLanguage,
            faPaintBrush,
            faLightbulb,
            faWindowMaximize,
            faStream,
            faBook,
            faComment,
            faSpinner,
            faSave
        );
    }
}
