import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment/moment';
import { AppConfigService } from '../../core/data/app-config.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { IMetricRange } from '../../core/models/metric-range.model';
import { IProductAttributeParameters } from '../../core/models/product-attribute-parameters.model';
import { IProductAttributeRow } from '../../core/models/product-attribute-row.model';
import { IProductDisplayAttributeMetric } from '../../core/models/product-display-attribute-metric.model';
import { IProductDisplayAttribute } from '../../core/models/product-display-attribute.model';

@Injectable({
    providedIn: 'root'
})
export class AttributeService {
    public apiUrl: string = this._appConfigService.apiUrl;
    public baseUrl: string = '/api/defaultproductattribute';
    public availableMetricsBaseUrl: string = '/api/defaultproductattribute/available';
    public apiProductAttributes: string = '/api/productattribute';
    public apiProductDisplayAttributes: string = '/api/productattributes/displayattributes';
    private _defaultProductDisplayAttributeMetrics: Array<IProductDisplayAttributeMetric>;
    private _availableProductDisplayAttributeMetrics: Array<IProductDisplayAttributeMetric>;
    private _productDisplayAttributeCache: Array<IProductDisplayAttribute>;

    public constructor(private readonly _http: HttpClient,
                       private readonly _appConfigService: AppConfigService,
                       private readonly _localStorageService: LocalStorageService) {
    }

    public async getDefaultProductDisplayAttributeMetrics(): Promise<Array<IProductDisplayAttributeMetric>> {
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}`);
        if (this._defaultProductDisplayAttributeMetrics) {
            return this._defaultProductDisplayAttributeMetrics;
        }
        this._defaultProductDisplayAttributeMetrics = await lastValueFrom(this._http.get<Array<IProductDisplayAttributeMetric>>(query));
        return this._defaultProductDisplayAttributeMetrics;
    }

    public async getAvailableProductDisplayAttributeMetrics(): Promise<Array<IProductDisplayAttributeMetric>> {
        const query: string = encodeURI(`${this.apiUrl}${this.availableMetricsBaseUrl}`);
        if (this._availableProductDisplayAttributeMetrics) {
            return this._availableProductDisplayAttributeMetrics;
        }
        this._availableProductDisplayAttributeMetrics = await lastValueFrom(this._http.get<Array<IProductDisplayAttributeMetric>>(query));
        return this._availableProductDisplayAttributeMetrics;
    }

    public async getProductAttributes(input: IProductAttributeParameters): Promise<Array<IProductAttributeRow>> {
        let time: string;
        if (input.time_range?.length) {
            const rangeValues: Array<string> = (input.time_range as string).split(' - ');
            const startDate: string = rangeValues[0];
            const endDate: string = rangeValues[1];
            time = `start:${moment(startDate).format('DDMMYYYY')},end:${moment(endDate).format('DDMMYYYY')}`;
        } else {
            const timeFilters: Array<string> = [];
            if (input.time_period?.length) {
                timeFilters.push(`recent:'${input.time_period}'`);
            } else {
                if (input.time_year?.length) {
                    timeFilters.push(`year:'${input.time_year}'`);
                }
                if (input.time_quarter?.length) {
                    timeFilters.push(`quarter:'${input.time_quarter}'`);
                }
                if (input.time_month?.length) {
                    timeFilters.push(`month:'${input.time_month}'`);
                }
            }
            time = timeFilters.join(',');
        }

        const departmentName: Array<string> = input.department
            ? input.department.map((value: string) => `department_name:'${value}'`)
            : [];
        const subDepartmentName: Array<string> = input.sub_department
            ? input.sub_department.map((value: string) => `sub_department_name:'${value}'`)
            : [];
        const categoryName: Array<string> = input.category
            ? input.category.map((value: string) => `category_name:'${value}'`)
            : [];
        const productTypeName: Array<string> = input.product_type
            ? input.product_type.map((value: string) => `product_type_name:'${value}'`)
            : [];

        const hierarchyFilters: string = `${departmentName
            .concat(subDepartmentName)
            .concat(categoryName)
            .concat(productTypeName)
            .join(',')}`;

        const activeMetricRanges: Array<string> = input.metric_range
            ? input.metric_range.map((value: IMetricRange) => `${value.metric_name}:${value.values[0]}:${value.values[1]}`)
            : [];

        const metricRangeFilters: string = `${activeMetricRanges.join(',')}`;

        // const locationFilters: Array<string> = [];
        //
        // if (input.location_company && input.location_company.length) {
        //     input.location_company.map((location: string) => {
        //         locationFilters.push(`fin_co_name:'${location}'`);
        //     });
        // }
        // if (input.location_division && input.location_division.length) {
        //     input.location_division.map((location: string) => {
        //         locationFilters.push(`division_descr:'${location}'`);
        //     });
        // }
        // if (input.location_area && input.location_area.length) {
        //     input.location_area.map((location: string) => {
        //         locationFilters.push(`trademark_descr:'${location}'`);
        //     });
        // }
        // if (input.location_region && input.location_region.length) {
        //     input.location_region.map((location: string) => {
        //         locationFilters.push(`region_descr:'${location}'`);
        //     });
        // }
        // if (input.location_store && input.location_store.length) {
        //     input.location_store.map((location: string) => {
        //         locationFilters.push(`store_name:'${location}'`);
        //     });
        // }
        const metaData: Array<string> = [];
        if (input.meta_data) {
            for (const property in input.meta_data) {
                if (input.meta_data[property]) {
                    for (const value of input.meta_data[property]) {
                        metaData.push(`${property}:'${value}'`);
                    }
                }
            }
        }

        let params: HttpParams = new HttpParams();
        if (input.ids) {
            params = params.set('ids', input.ids);
        }

        if (hierarchyFilters) {
            params = params.set('hierarchy_filters', hierarchyFilters);
        }
        if (metricRangeFilters) {
            params = params.set('metric_range_filters', metricRangeFilters);
        }
        // if (locationFilters) {
        //     params = params.set('location_filters', locationFilters.join(','));
        // }
        if (metaData) {
            params = params.set('meta_filters', metaData.join(','));
        }
        if (time) {
            params = params.set('time_period', time);
        }
        if (input.fieldsSet) {
            params = params.set('fields', input.fieldsSet);
        }
        if (input.sortOrder) {
            params = params.set('sort_order', input.sortOrder as string);
        }
        if (input.pageSize) {
            params = params.set('page_size', input.pageSize?.toString());
        }

        const cachedQuery: string = this._localStorageService.getItem<string>('products-attr-query-cache'+ input.attribute_column);
        if (cachedQuery === params.toString()){
            return this._localStorageService.getItem<Array<IProductAttributeRow>>('products-attr-row-cache'+ input.attribute_column);
        }

        const rows: Array<IProductAttributeRow> = await  firstValueFrom(this._http.get<Array<IProductAttributeRow>>(
            `${this.apiUrl + this.apiProductAttributes}/${input.attribute_column}`,
            {
                params
            }
        ));
        this._localStorageService.setItem<string>('products-attr-query-cache'+ input.attribute_column,params.toString());
        this._localStorageService.setItem<Array<IProductAttributeRow>>('products-attr-row-cache'+ input.attribute_column, rows);

        return rows;
        // Todo: Fix local cache
        // console.log('Current cache', Object.keys(this._productAttributeCache));
        // const cacheKey: string = input.attribute_column + params.toString();
        // if (this._productAttributeCache[cacheKey]) {
        //     console.log('returning pa from cache', input.attribute_column);
        //     return this._productAttributeCache[input.attribute_column + input.attribute_column];
        // } else {
        //     console.log('Setting pa cache 1',input.attribute_column, params.toString());
        //     this._productAttributeCache[cacheKey] = await firstValueFrom(this._http.get<Array<IProductAttributeRow>>(
        //         `${this.apiUrl + this.apiProductAttributes}/${input.attribute_column}`,
        //         {
        //             params
        //         }
        //     ));
        //     return this._productAttributeCache[input.attribute_column + input.attribute_column];
        // }
        // console.log('Query params', input.attribute_column, params.toString());
        // return this._http.get<Array<IProductAttributeRow>>(
        //     `${this.apiUrl + this.apiProductAttributes}/${input.attribute_column}`,
        //     {
        //         params
        //     }
        // );
    }

    public async getProductDisplayAttributes(): Promise<Array<IProductDisplayAttribute>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductDisplayAttributes}`);

        if (this._productDisplayAttributeCache) {
            return this._productDisplayAttributeCache;
        }
        let displayAttributes: Array<IProductDisplayAttribute> = await firstValueFrom(this._http.get<Array<IProductDisplayAttribute>>(query));
        displayAttributes = displayAttributes.map((x: IProductDisplayAttribute) => {
            const words: Array<string> = x.display_name.toLowerCase().split(' ');
            for (let i: number = 0; i < words.length; i++) {
                words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
            }
            x.display_name = words.join(' ');
            return x;
        });
        this._productDisplayAttributeCache = displayAttributes;
        return this._productDisplayAttributeCache;
    }
}
